//fonts

$font_0: "Helvetica Neue";
$font_1: "Helvetica";
$font-themify: themify;
$font-awesome: FontAwesome;
$assetspath: "/assets/images";
//colors
$white: #ffffff;
$yellow: #ffff00;
$white-dark: #eeeeee;
$black: #000000;
$grey01: #111111;
$dark-font: #222222;
$grey-dark: #2d2a25;
$font-color: #333333;
$grey: #2a2929;
$grey-light: #a1a1a1;
$grey-middle: #1d1d1d;
$border: #dddada;
$round-border: #dddddd;
$grey-lighter: #f9f9f9;
$theme-orange: #ff4c3b;
// $theme-deafult: #ff4c3b;
$theme-silver: #c0c0c0;
// $theme-dark: #141414;
$theme-dark: #141414;
$theme-deafult: rgb(5, 5, 5);
$theme-yellow: rgb(255, 212, 38);
$theme-blue:rgb(0, 91, 255);;
$theme-telegram:rgb(0, 136, 204);
$theme-magenta: rgb(203, 17, 171);
$grey-darken: #393230;
$grey-link: #948e8c;
$grey-font: #938d8c;
$dark-footer: #2e2726;
$form-bg: #f5f2f2;
$grey-shade: #7f786d;
$darker-footer: #25221e;
$border-color: #38352f;
$border-grey: #f1f5f4;
$font-grey: #aaaaaa;
$star-yellow: #ffa200;
$border-bottom-g: #525252;
$top-header: #f8f8f8;
$header-font: #999999;
$hashtag-font: #999999;
$shadow: #ededed;
$box-border: #ecececa8;
$grey-about: #f7f7f7;
$grey2: #555555;
$grey3: #efefef;
$grey4: #444444;
$grey5: #888888;
$grey6: #6f6f6f;
$grey7: #40494f;
$grey8: #c0c0c0;
$sidebar-border: #f5f2f2;
$sidebar-color: #938d8c;
$modal: #ffba00;
$bg-color: #d0edff;
$bg-color1: #f1e7e6;
$bg-color2: #bfbfbf;
$light-grey: #d0edff;
$pink: #f1e7e6;
$blue: #bfbfbf;
$icon: #6f6f6f;
$color-red: #ff4c3b;
$gradient1: #01effc;
$gradient2: #485ff2;
$left-sidebar: #2b2b2b;

// dark layput variable //
$dark-body: #2b2b2b;
$dark-top: #232323;
$dark-border: #404040;
$dark-link: #cbcbcb;
$dark-span: #929292;
$dark-footer-bg: #383838;

$white-1: #f1f3f5;
$white-2: #eaedef;
$white-3: #e7eaec;
$white-4: #dee2e6;
$white-5: #cfd4da;
